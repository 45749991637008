<template>
  <main class="manual-sales-record-settings">
    <PageTitle
      title="客人消費登錄設定"
      icon="chevron_left"
      hideBtn
      style="padding-bottom: 24px"
      @iconClick="$router.push({ name: 'SalesParameterSetting' })"
    />

    <div v-loading="loading" class="config-block-container">
      <ManualSalesRecordBasicSettings class="card-container container-wrapper" :configData="configData" @refresh="refresh" />
      <ManualSalesRecordCashbackSettings class="card-container container-wrapper" :configData="configData" @refresh="refresh" />
      <ManualSalesRecordWalletSettings class="card-container container-wrapper" :configData="configData" @refresh="refresh" />
      <ManualSalesRecordOfflinePaymentSettings class="card-container container-wrapper" :configData="configData" @refresh="refresh" />
    </div>
  </main>
</template>

<script>
import { defineComponent, ref, onMounted } from 'vue'
import PageTitle from '@/components/Title/PageTitle.vue'
import ManualSalesRecordBasicSettings from './components/ManualSalesRecordBasicSettings.vue'
import ManualSalesRecordCashbackSettings from './components/ManualSalesRecordCashbackSettings.vue'
import ManualSalesRecordWalletSettings from './components/ManualSalesRecordWalletSettings.vue'
import ManualSalesRecordOfflinePaymentSettings from './components/ManualSalesRecordOfflinePaymentSettings.vue'
import { GetSalesClientConfig } from '@/api/sales'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'ManualSalesRecordSettings',
  components: { PageTitle, ManualSalesRecordBasicSettings, ManualSalesRecordCashbackSettings, ManualSalesRecordWalletSettings, ManualSalesRecordOfflinePaymentSettings },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const configData = ref({})
    const loading = ref(false)
    const getSalesClientConfig = async () => {
      const [res, err] = await GetSalesClientConfig({
        shopId: shopId.value,
      })
      if (err) {
        window.$mesage.error(err)
        return
      }
      configData.value = res
    }

    const refresh = async () => {
      loading.value = true
      await getSalesClientConfig()
      loading.value = false
    }

    onMounted(async () => {
      refresh()
    })
    return {
      loading,
      refresh,
      configData,
    }
  },
})
</script>

<style scoped lang="postcss">
.config-block-container {
  @apply flex flex-col gap-[24px];
}
.container-wrapper {
  @apply px-[32px] py-[24px];
}
</style>
