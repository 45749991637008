<template>
  <div class="manual--sales-record-basic-settings">
    <SectionTitle title="現場付款設定" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="220px">
      <el-form-item
        v-for="info in displayData"
        :key="info.label"
        :label="info.label"
      >
        {{ info.value }}
      </el-form-item>
    </el-form>

    <ManualSalesRecordOfflinePaymentSettingsEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ManualSalesRecordOfflinePaymentSettingsEditModal from './ManualSalesRecordOfflinePaymentSettingsEditModal.vue'
import { get, find } from 'lodash'

export default defineComponent({
  name: 'ManualSalesRecordOfflinePaymentSettings',
  components: { SectionTitle, ManualSalesRecordOfflinePaymentSettingsEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props, { emit }) {
    const loadig = ref(false)
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      const paymentSettings = get(props.configData, 'paymentSettings')
      const config = find(paymentSettings, { type: 'offline' })
      const data = {
        enable: get(config, 'enable'),
      }
      return [
        { label: '開啟現場付款', value: data.enable ? '開啟' : '關閉' },
      ]
    })
    return {
      modal,
      loadig,
      displayData,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
