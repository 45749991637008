<template>
  <div class="manual--sales-record-basic-settings">
    <!-- <p class="card-title">基本設定</p> -->
    <SectionTitle title="基本設定" @edit="modal.edit = true" />
    <el-form label-position="left" label-width="220px">
      <el-form-item label="開放客人進行消費登錄">
        {{ displayData.enable }}
      </el-form-item>
      <el-form-item label="綁定銷售產品">
        {{ displayData.productName }}
      </el-form-item>
      <el-form-item label="開放客人修改產品金額">
        {{ displayData.allowEditPrice }}
      </el-form-item>
    </el-form>

    <ManualSalesRecordBasicSettingsEditModal
      v-if="modal.edit"
      :configData="configData"
      @close="modal.edit = false"
      @refresh="$emit('refresh')"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, reactive } from 'vue'
import SectionTitle from '@/components/Title/SectionTitle.vue'
import ManualSalesRecordBasicSettingsEditModal from './ManualSalesRecordBasicSettingsEditModal.vue'
import { get } from 'lodash'

export default defineComponent({
  name: 'ManualSalesRecordBasicSettings',
  components: { SectionTitle, ManualSalesRecordBasicSettingsEditModal },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  emits: ['refresh'],
  setup (props) {
    const loadig = ref(false)
    const modal = reactive({
      edit: false,
    })
    const displayData = computed(() => {
      const config = props.configData
      return {
        enable: get(config, 'enable') ? '啟用' : '關閉',
        productName: get(config, 'SingleProduct.name') || '',
        allowEditPrice: get(config, 'allowClientEditPrice') ? '啟用' : '關閉',
      }
    })
    return {
      modal,
      loadig,
      displayData,
    }
  },
})
</script>

<style lang="postcss" scoped>

</style>
