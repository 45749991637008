<template>
  <BaseDialog width="610px" title="回饋金折抵設定" @close="$emit('close')" @confirm="onConfirm">
    <el-form ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <el-form-item prop="enable">
        <template slot="label">
          <FormItemTooltipLabel label="開放使用回饋金折抵" :tooltipWidth="200">
            開啟後，客人進入消費登錄頁面後將顯示回饋金折抵畫面
          </FormItemTooltipLabel>
        </template>
        <el-switch
          v-model="formData.enable"
          inactive-text="關閉"
          active-text="開啟"
        />
      </el-form-item>

      <template v-if="formData.enable">
        <!-- 預設折抵 -->
        <el-form-item prop="defaultUseEnable">
          <template slot="label">
            <FormItemTooltipLabel label="啟用訂單預設折抵" :tooltipWidth="200">
              假設設定預設折抵為 10%，客人進入消費登錄頁面後，系統將預設折抵訂單金額 10% 的回饋金
            </FormItemTooltipLabel>
          </template>
          <el-switch
            v-model="formData.defaultUseEnable"
            inactive-text="關閉"
            active-text="開啟"
          />
        </el-form-item>
        <el-form-item label="預設折抵（%）" prop="defaultUseRate">
          <el-input v-model="formData.defaultUseRate" :disabled="!formData.defaultUseEnable" placeholder="請填寫數字" />
        </el-form-item>

        <!-- 折抵上限 -->
        <el-form-item prop="useRateEnable">
          <template slot="label">
            <FormItemTooltipLabel label="啟用訂單折抵上限" :tooltipWidth="200">
              假設設定折抵上限為 15%，客人進入消費登錄頁面後，不可折抵超過訂單金額 15% 的回饋金
              注意：折抵上限不可以小於預設折抵金額
            </FormItemTooltipLabel>
          </template>
          <el-switch
            v-model="formData.useRateEnable"
            inactive-text="關閉"
            active-text="開啟"
          />
        </el-form-item>
        <el-form-item label="折抵上限（%）" prop="useRateLimit">
          <el-input v-model="formData.useRateLimit" :disabled="!formData.useRateEnable" placeholder="請填寫數字" />
        </el-form-item>
      </template>
    </el-form>
  </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Dialog/BaseDialog.vue'
import FormItemTooltipLabel from '@/components/Form/FormItemTooltipLabel.vue'
import { useShop } from '@/use/shop'
import { useBaseForm } from '@/use/useForm'
import { isDigitRules, noEmptyRules, rangeRules } from '@/validation'
import { UpdateSalesClientConfig } from '@/api/sales'
import { computed, defineComponent, onMounted, set } from 'vue'
import { get, filter, find } from 'lodash'
export default defineComponent({
  name: 'ManualSalesRecordCashbackSettingsEditModal',
  components: { BaseDialog, FormItemTooltipLabel },
  props: {
    configData: { type: Object, default: () => ({}) },
  },
  setup (props, { emit }) {
    const { shopId } = useShop()
    const { initFormData, formData, formRef, onSubmit, loading } = useBaseForm()
    initFormData({
      enable: false,
      defaultUseEnable: false,
      defaultUseRate: null,
      useRateEnable: false,
      useRateLimit: null,
    })
    const formRules = computed(() => {
      const rules = {
        enable: [noEmptyRules()],
      }

      if (formData.defaultUseEnable) {
        rules.defaultUseRate = [noEmptyRules(), isDigitRules(), rangeRules(0, 100)]
      }
      if (formData.useRateEnable) {
        let minRange = 0
        if (formData.defaultUseEnable && formData.defaultUseRate) {
          minRange = Number(formData.defaultUseRate)
        }
        rules.useRateLimit = [noEmptyRules(), isDigitRules(), rangeRules(minRange, 100)]
      }

      return rules
    })

    const submitData = computed(() => {
      const discountSettings = get(props.configData, 'discountSettings')
      const settingsData = filter(discountSettings, setting => setting.type !== 'useCashback')

      return {
        shopId: shopId.value,
        discountSettings: [
          ...settingsData,
          {
            name: '回饋金',
            type: 'useCashback',
            order: 1,
            enable: formData.enable,
            useRateLimit: Number(formData.useRateLimit) / 100,
            useRateEnable: formData.useRateEnable,
            defaultUseRate: Number(formData.defaultUseRate) / 100,
            defaultUseEnable: formData.defaultUseEnable,
          },
        ],
      }
    })
    const handleSubmit = async (data) => {
      const [res, err] = await UpdateSalesClientConfig({
        shopId: shopId.vavlue,
        ...data,
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('更新成功！')
      emit('refresh')
      emit('close')
    }

    const syncData = () => {
      const discountSettings = get(props.configData, 'discountSettings')
      const config = find(discountSettings, { type: 'useCashback' })
      const defaultUseRate = get(config, 'defaultUseRate')
      const useRateLimit = get(config, 'useRateLimit')

      formData.enable = get(config, 'enable') || false
      formData.defaultUseEnable = get(config, 'defaultUseEnable') || false
      formData.defaultUseRate = defaultUseRate ? defaultUseRate * 100 : null
      formData.useRateEnable = get(config, 'useRateEnable') || false
      formData.useRateLimit = useRateLimit ? useRateLimit * 100 : null
    }

    const onConfirm = async () => {
      await onSubmit(handleSubmit, submitData.value)
    }
    onMounted(() => {
      syncData()
    })
    return { formData, formRef, formRules, onConfirm, loading }
  },
})
</script>

<style lang="postcss" scoped>

</style>
